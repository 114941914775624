<template>
  <div class="container">
    <div v-if="!user.IsCarrier">
      <not-found-info :item="filteredDisplayedAdsForSupplier" :title="'İlan'" :emptyName="'advert'" />
      <myAd v-for="(item, index) in filteredDisplayedAdsForSupplier" :key="index" :detail="item" />
      <div class="col-md-12 d-flex justify-content-center" v-if="pages.length > 1">
        <nav aria-label="Page navigation example">
          <ul class="pagination">
            <li class="page-item">
              <button class="page-link" type="button" aria-label="Previous" @click="page--">
                <span aria-hidden="true">&laquo;</span>
                <span class="sr-only">Previous</span>
              </button>
            </li>
            <li v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)" :key="index" @click="page = pageNumber" class="page-item" :class="{ active: pageNumber == page }">
              <button type="button" class="page-link text-white active">
                {{ pageNumber }}
              </button>
            </li>
            <li class="page-item">
              <a class="page-link" href="#" aria-label="Next" @click="page++">
                <span aria-hidden="true">&raquo;</span>
                <span class="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div v-else>
      <div class="d-flex flex-row justify-content-start mb-3">
        <div class="">
          <el-select v-model="filters.WarehouseCityId" class="form-control" placeholder="Yükleme Şehri" filterable clearable @clear="clearCityId('warehouse')">
            <el-option v-for="(item, index) in filteredCities()" :key="index" :label="item.Title" :value="item.ID"> </el-option>
          </el-select>
        </div>
        <div class="ps-3">
          <el-select v-model="filters.DeliveryCompanyCityId" class="form-control" placeholder="Teslimat Şehri" filterable clearable @clear="clearCityId('delivery')">
            <el-option v-for="(item, index) in filteredCities()" :key="index" :label="item.Title" :value="item.ID"> </el-option>
          </el-select>
        </div>
        <!-- 
        <div class="col-md-3 col-sm-12 text-center order-1 order-md-1">
          <el-select v-model="filters.TrailerId" class="form-control w-sm-100" placeholder="Dorse Tipi Seçiniz..." clearable>
            <el-option v-for="item in filteredTrailerTypes()" :key="item.ID" :label="item.Title" :value="item.ID"> </el-option>
          </el-select>
        </div> -->

        <!-- <div class="col-md-3 col-sm-12 order-2 text-center order-md-3">
          <el-select v-model="filters.PaymentType" class="form-control" placeholder="Ödeme Tipi Seçiniz..." clearable>
            <el-option v-for="item in paymentOptions" :key="item.key" :label="item.Title" :value="item.key"> </el-option>
          </el-select>
        </div> -->
      </div>
      <div v-if="showDistanceFilter" class="col-12mt-3 order-3 order-md-2">
        <label style="margin: 0" for="distance">Mesafeyi Belirle(km)</label>
        <el-slider v-model="filters.MaxDistance" name="distance" :step="10" :max="maxSliderValue"></el-slider>
      </div>
      <not-found-info :item="filteredDisplayedAdsForCarrier" :title="'İlan'" :emptyName="'advert'" />
      <myAd v-for="(item, index) in filteredDisplayedAdsForCarrier" :key="index" :detail="item" />
      <div class="col-md-12 d-flex justify-content-center mb-5" v-if="pages.length > 1">
        <nav aria-label="Page navigation example">
          <ul class="pagination">
            <li class="page-item">
              <button class="page-link" type="button" aria-label="Previous" @click="page--">
                <span aria-hidden="true">&laquo;</span>
                <span class="sr-only">Previous</span>
              </button>
            </li>
            <li v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)" :key="index" @click="page = pageNumber" class="page-item" :class="{ active: pageNumber == page }">
              <button type="button" class="page-link text-white active">
                {{ pageNumber }}
              </button>
            </li>
            <li class="page-item">
              <a class="page-link" href="#" aria-label="Next" @click="page++">
                <span aria-hidden="true">&raquo;</span>
                <span class="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import myAd from "../../components/myAd/myAd";
import { lazyDispatch } from "../../helpers/generalHelper";
import { mapGetters } from "vuex";
import NotFoundInfo from "../../components/notFound/notFoundInfo.vue";
import META_DATA from "../../constants/metaData";

export default {
  metaInfo() {
    return {
      title: META_DATA.ADS.Title,
    };
  },
  components: {
    myAd,
    NotFoundInfo,
  },
  data() {
    return {
      value1: 0,
      filters: {
        AdvertId: null,
        WarehouseCityId: null,
        WarehouseLat: null,
        WarehouseLon: null,
        DeliveryCompanyCityId: null,
        DeliveryCompanyLat: null,
        DeliveryCompanyLon: null,
        FilterMe: false,
        Skip: 0,
        Take: 100,
        SearchTerm: "",
      },
      paymentOptions: [
        {
          key: 0,
          value: "GÖÇ",
          Title: "GÖÇ",
        },
        {
          key: 3,
          value: "Havale",
          Title: "Havale",
        },
        {
          key: 2,
          value: "Nakit",
          Title: "Nakit",
        },
      ],
      page: 1,
      perPage: 10,
      pages: [],
      maxSliderValue: 2000,
      showDistanceFilter: false,
    };
  },
  async created() {
    const user = JSON.parse(JSON.stringify(this.user));
    const query = this.$route.query;

    const setIsCarrier = async (bool) => {
      console.log("isCarrier " + bool);
      this.$store.commit("setIsCarrier", bool);
    };

    // set guest role if guest
    if (!user.ID) await setIsCarrier(query.misafirRol === "nakliyeci");

    await this.$store.dispatch("getAdverts", this.filters);
    await this.$store.dispatch("getCities");
  },
  computed: {
    ...mapGetters({
      ads: "advertsDetail",
      user: "userState",
      trailerTypes: "trailerDetails",
      cityState: "cityState",
    }),
    displayedAds() {
      return this.paginate(this.ads);
    },
    filteredDisplayedAdsForSupplier() {
      return this.displayedAds?.filter((item) => item.Status === 0 || item.Status === 6);
    },
    filteredDisplayedAdsForCarrier() {
      return this.displayedAds?.filter((item) => item.Status === 0);
    },
    loading() {
      return this.$store.getters["loading"];
    },
  },
  methods: {
    filteredCities() {
      return this.cityState?.filter((item) => item && item.ID);
    },
    filteredTrailerTypes() {
      return this.trailerTypes?.filter((item) => item && item.ID);
    },
    formatTooltip(val) {
      return val / 100;
    },
    setPages() {
      let numberOfPages = Math.ceil(this.ads?.length / this.perPage);
      this.pages = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(ads) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return ads?.slice(from, to);
    },
    clearCityId(key) {
      switch (key) {
        case 'warehouse':
          this.filters.WarehouseCityId = null
          break;
      
          case 'delivery':
          this.filters.DeliveryCompanyCityId = null
          break;
      }

      if ((this.filters.WarehouseCityId === "" || this.filters.WarehouseCityId == null) && (this.filters.DeliveryCompanyCityId === "" || this.filters.DeliveryCompanyCityId == null) && this.filters.FilterMe === true) this.filters.FilterMe = false;

    },
    async geolocate() {
      // navigator.geolocation.getCurrentPosition(
      //   (position) => {
      //     this.filters.Lat = position.coords.latitude;
      //     this.filters.Lon = position.coords.longitude;
      //     this.showDistanceFilter = true;
      //   },
      //   function(error) {
      //     // window.sendNotification.warning("Mesafe filtresi kullanabilmeniz için konum izni vermeniz gerekmektedir!");
      //   },
      // );
    },
  },
  watch: {
    filters: {
      handler: async function() {
        if (this.filters.PaymentType === "") this.filters.PaymentType = null;
        const filteredCities = this.filteredCities();
        const warehouseCity = filteredCities.find((x) => x.ID == this.filters.WarehouseCityId);
        const deliveryCompanyCity = filteredCities.find((x) => x.ID == this.filters.DeliveryCompanyCityId);
        this.filters.WarehouseLat = parseFloat(warehouseCity?.Latitude);
        this.filters.WarehouseLon = parseFloat(warehouseCity?.Longitude);
        this.filters.FilterMe = true;

        await lazyDispatch("getAdverts", this.filters);
      },
      deep: true,
    },
    ads() {
      this.setPages();
    },
    page() {
      if (this.page == 0) {
        this.page = this.pages.length;
      } else if (this.page > this.pages.length) {
        this.page = 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
